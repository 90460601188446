














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { studentService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';
import {
  APIExtraAttributes,
  BaseProduct,
  ProductCourse,
  ProductLibrary,
  ProductPaginatedResponse,
} from '@/types/products';
import { sortLocaleWithKey } from '@/libs/strings';
import MButton from '@/shared/components/MButton.vue';
import { LocationMemberSegment } from '@/libs/member';
import debounce from 'lodash.debounce';

type Product = BaseProduct & ProductLibrary & ProductCourse;

const AuthModule = namespace('auth');
const ProductModule = namespace('product');
const SchoolModule = namespace('school');

@Component({
  components: {
    MButton,
  },
})
export default class AssignProductModal extends Vue {
  @Prop({ required: true }) modalId!: string;
  @Prop({ required: true }) members!: any[];

  @AuthModule.State loggedUser!: any;
  @SchoolModule.State selectedSchool!: any;

  @ProductModule.Action getAllProducts!: (config: {
    params: APIExtraAttributes;
  }) => Promise<ProductPaginatedResponse<Product>>;

  isLoading: boolean = false;
  productsModel: any[] = [];
  productsOptions: any[] = [];
  isSearching: Boolean = false;

  async mounted() {
    await this.getProducts();
    this.closeModal();
    this.searchProduct = debounce(this.searchProduct, 500);
  }

  closeModal() {
    this.$root.$on('bv::modal::hide', (_: any, modalId: string) => {
      modalId === 'assign-product-modal' && this.$emit('closeModal');
    });
  }

  async getProducts(search?: string) {
    try {
      this.isSearching = true;
      const params: APIExtraAttributes = [{ key: 'page', value: 1 }, { key: 'per_page', value: 50 }, { key: 'request_attributes', value: 'library_resource' }];

      if (search?.length) params.push({ key: 'title', value: search });

      const { school_products } = await this.getAllProducts({ params });
      this.productsOptions = sortLocaleWithKey(school_products, 'title') || [];
    } finally {
      this.isSearching = false;
    }
  }

  searchProduct(search) {
    return this.getProducts(search);
  }

  assignProduct() {
    this.isLoading = true;

    this.$emit('isLoading', true);
    Promise.all(
      this.members.map(async ({ first_name, email, cpf, phone }) => {
        const payload = {
          first_name,
          email,
          cpf,
          phone,
          type: 'Student',
          enrollments: this.formatEnrollments(),
        };

        return await studentService.createInvite(payload);
      })
    )
      .then(() => {
        ToastHelper.successMessage(this.$t('sparkmembers.members-list.assign-product-modal.toast.success'));
        this.$emit('loadMembers');
        this.$emit('closeModal');
        this.trackingMemberEnrolled(this.members.length, this.formatEnrollments().length, this.getProductKind());
      })
      .catch(() => {
        ToastHelper.dangerMessage(this.$t('sparkmembers.members-list.assign-product-modal.toast.error'));
      })
      .finally(() => (this.isLoading = false));
  }

  getProductKind() {
    return this.productsModel.map(product => product.library_resource.resource.kind || product.library_resource.resource.type)
  }

  formatEnrollments() {
    return this.productsModel.map((product: any) => ({
      enrollment: {
        school_product_id: Number(product.id),
        status: 'active',
        unlimited: false,
        available_until: product.available_until || null,
      },
    }));
  }

  trackingMemberEnrolled(member_count: number, count_product: number, products_type: any[]) {
    const location = this.$route.params.id
      ? LocationMemberSegment.MEMBERS_DETAILS_PAGE
      : LocationMemberSegment.MEMBERS_LIST_PAGE;
    TrackingHelper.trackMemberEnrolled(
      location,
      this.loggedUser.email,
      this.selectedSchool.id,
      member_count,
      count_product,
      products_type
    );
  }
}
